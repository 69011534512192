<template>
  <div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "./../node_modules/bulma/bulma.sass";
@import "./styles/main.scss";
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: hsl(210, 29%, 24%);
  background-color: #101722;
}
</style>
